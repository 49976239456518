<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">查看二维码</div>
        <div class="rb1" @click="goback">
          <img src="@/assets/fh.png" class="icon" />
          <div class="text">返回</div>
        </div>      
      </div>
      <div class="content">
        <div class="form">
          <div class="item">
            <div class="title">名称</div>
            <div class="text hui">{{site_code}}</div>
          </div>
        </div>
        <img class="img" :src="pic" />
        <div class="tip">加油二维码供打印</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import { isEmpty, isTel } from '@/utils';
import qs from 'qs';
export default {
  created() {
    this.clientcode = this.$route.query.clientcode;
    this.site_code = this.$route.query.site_code;
    this.query();
  },
  data() {
    return {
      clientcode: '',
      site_code: '',
      pic: '/img/wutu.png'
    };
  },
  methods: {
    goback() {
      this.$router.replace({path:'/refueling/my', query:{}});
    },
    async query() {
      let { data } = await this.axios.post(
        '/ctid/JY_ITlService/jy_selectclient',
        qs.stringify({ 
          clientcode: this.clientcode 
        })
      );
      if (data.flag === '0') {
        //this.pic = data.data.pic; 
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;   
        this.pic = testobj.pic; 
      }
    }

  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.content {
  margin-top: 0px;
}
.container {
  padding: 0px 0px 20px 0px;
}
.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 50px;
    color: #fff;
    font-size: 35px;
    float: left;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
}
.img {
  width: 600px;
  display: block;
  margin: 60px auto 0;
}
.tip {
  text-align: center;
  font-size: 28px;
  line-height: 40px;
  margin-top: 20px;
}
.rb {
  height: 72px;
  width: 278px;
  overflow: hidden;
  border-radius: 38px;
  border: 2px solid rgba(0, 29, 255, 1);
  margin: 60px auto 60px auto;
  .icon {
    width: 48px;
    height: 48px;
    margin: 10px;
    display: block;
    float: left;
  }
  .text {
    float: left;
    color: rgba(0, 29, 255, 1);
    font-size: 30px;
    line-height: 72px;
  }
}
</style>